import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { RiArrowGoBackLine } from 'react-icons/ri';
import { Content } from 'antd/es/layout/layout';
import AppBody from '../../components/Layout/AppBody';
import { connect } from 'react-redux';
import { addLesson, getLesson, updateLesson } from '../../store/action/courses/courseAction';

const AddLesson = (props) => {
    const navigate = useNavigate();
    const {id, moduleId, courseId} = useParams();
    const [form] = Form.useForm();
    const [isUpdate, setIsUpdate] = useState(false);


    const handleSubmit = (values) => {
        setIsUpdate(true);
        let data = {title: values.title, content: values.content, type: 'video', totalGrade: 0};
        if(id) {
            if(data.attachment) {
                data = {...data, attachment: values.attachment[0].originFileObj};
            }
            props.updateLesson_(id, data);
        } else {
            data = {...data, attachment: values.attachment[0].originFileObj, courseId, moduleId};
            props.addLesson_(data);
        }
    };

    const handleBackClick = () => {
        navigate(-1);
    };

    useEffect(() => {
        if(id) {
            props.getLesson_(id);
        }
    }, []);

    useEffect(() => {
        if(id && props.lesson) {
            form.setFieldsValue({
                title: props.lesson.title,
                content: props.lesson.content,
            });
        }
    }, [props.lesson]);
    useEffect(() => {
        if(isUpdate && props.lessonUpdate && props.lessonUpdate.message && props.lessonUpdate.message.includes("Success")) {
            navigate(-1);
        } else if(isUpdate && props.addLesson && props.addLesson.message && props.addLesson.message.includes("Success")) {
            navigate(-1);
        }
    }, [props.lessonUpdate, props.addLesson]);

    return (
        <AppBody
        heading={"Manage Course"}
        title="Add Lesson"
        extra={
            <Button
                onClick={handleBackClick}
                style={{ backgroundColor: "#FF6767", borderRadius: "30px", padding: "8px" }}
            >
                <RiArrowGoBackLine style={{ color: "white", fontWeight: "700" }} />
            </Button>
        }
        style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Content style={{ padding: '24px' }}>
                <Form layout="vertical" onFinish={handleSubmit} 
                    requiredMark={false} form={form}
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[{ required: true, message: 'Please input the title!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Attachment"
                        name="attachment"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
                        rules={[{ required: !id ? true: false, message: 'Please upload a video!' }]}
                    >
                        <Upload.Dragger 
                            name="files" 
                            listType="picture"
                            accept="video/*"
                        >
                            <p className="ant-upload-drag-icon">
                                <UploadOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag video file to this area to upload</p>
                        </Upload.Dragger>
                    </Form.Item>

                    <Form.Item
                        label="Description"
                        name="content"
                        rules={[{ required: true, message: 'Please input the description!' }]}
                    >
                        <Input.TextArea rows={4} />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ display: 'block', margin: '0 auto' }}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Content>
        </AppBody>
    );
};

const mapStateToProps = (state) => ({
    lesson: state.lessonDetail?.response,
    lessonUpdate: state.lessonUpdate?.response,
    addLesson: state.addLesson?.response,
});

const mapDispatchToProps = (dispatch) => ({
    getLesson_: (id) => dispatch(getLesson(id)),
    updateLesson_: (id, data) => dispatch(updateLesson(id, data)),
    addLesson_: (data) => dispatch(addLesson(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddLesson);