import React, { useEffect, useState } from 'react';
import { Layout, Table, Radio, Form, Button, Select } from 'antd';
import AppBody from '../../components/Layout/AppBody';
import { addCourseCriteria, getCourses } from '../../store/action/courses/courseAction';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const { Content } = Layout;
const { Option } = Select;

const SetCourseMap = (props) => {
    const [selectedType, setSelectedType] = useState('client');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [location, setLocation] = useState([]);
    const [department, setDepartment] = useState([]);
    const [center, setCenter] = useState([]);
    const [branch, setBranch] = useState([]);
    const [group, setGroup] = useState([]);
    const [designation, setDesignation] = useState([]);
    const [courses, setCourses] = useState([]);
    const [isDirty, setIsDirty] = useState(false);
    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        props.getCourses_();
        if(id)
            setSelectedRowKeys([parseInt(id)]);
    }, []);
    useEffect(() => {
        if(props.courseList && props.courseList.length > 0){
            setCourses(props.courseList.map(course => {
                    return {
                        key: course.courseId,
                        courseId: course.courseId,
                        title: course.title,
                    };
            }));
        }
    }, [props.courseList]);
    useEffect(() => {
        if(selectedType === 'client'){
            setDepartment([]);
            setBranch([]);
            setDesignation([]);
        } else if(selectedType === 'employee'){
            setLocation([]);
            setCenter([]);
            setGroup([]);
        } else {
            setLocation([]);
            setDepartment([]);
            setCenter([]);
            setBranch([]);
            setGroup([]);
            setDesignation([]);
        }
    }, [selectedType]);
    useEffect(() => {
        if(isDirty && props.addCourseCriteria && props.addCourseCriteria.message && props.addCourseCriteria.message.includes('Success')){
            navigate(-1);
        }
    }, [addCourseCriteria, isDirty])

    const columns = [
        {
            title: 'Course ID',
            dataIndex: 'courseId',
            key: 'courseId',
            sorter: (a, b) => a.courseId - b.courseId,
            defaultSortOrder: 'descend',
        },
        {
            title: 'Course Title',
            dataIndex: 'title',
            key: 'title',
            sorter: true,
        },
    ];


    const handleTypeChange = (e) => {
        setSelectedType(e.target.value);
    };

    const submitForm = () => {
        setIsDirty(true);
        const data = {
            courseIds: selectedRowKeys,
            userRole : selectedType === "All" ? ["*"] : [selectedType],
        }
        if(selectedType === 'client'){
            data.location = location.includes('*') ? ['*'] : location;
            data.center = center.includes('*') ? ['*'] : center;
            data.group = group.includes('*') ? ['*'] : group;
            data.branch = [];
            data.designation = [];
            data.department = [];
        } else if(selectedType === 'employee'){
            data.department = department.includes('*') ? ['*'] : department;
            data.branch = branch.includes('*') ? ['*'] : branch;
            data.designation = designation.includes('*') ? ['*'] : designation;
            data.location = [];
            data.center = [];
            data.group = [];
        } else {
            data.location = [];
            data.center = [];
            data.group = [];
            data.branch = [];
            data.designation = [];
            data.department = [];
        }
        props.addCourseCriteria_(data);
    };
    const resetForm = () => {
        setSelectedType('client');
        setLocation([]);
        setDepartment([]);
        setCenter([]);
        setBranch([]);
        setGroup([]);
        setDesignation([]);
    }

    const checkAll = (value, item) => {
        if(value.includes('*') && !item.includes('*')) {
            return ['*'];
        } else {
            return value.filter(v => v !== '*');
        }
    }

    const renderDynamicInputs = () => {
        if (selectedType === 'All') return null;

        return (
            <>
                <Select
                    mode="multiple"
                    style={{ width: 'calc(30% - 16px)', marginRight: 8 }}
                    onChange={(value)=>{selectedType === 'client' ? setLocation(checkAll(value, location)) : setDepartment(checkAll(value, department))}}
                    value={selectedType === 'client' ? location : department}
                    placeholder={selectedType === 'client' ? 'Location' : selectedType === 'employee' ? 'Department' : 'No Filters'}
                >
                    {/* Options based on userType */}
                    <Option value="*">All</Option>
                    {selectedType === 'client' && <>
                        <Option value="1">Location 1</Option>
                        <Option value="2">Location 2</Option>
                    </>}
                    {selectedType === 'employee' && <>
                        <Option value="1">Department 1</Option>
                        <Option value="2">Department 2</Option>
                    </>}
                </Select>
                <Select
                    mode="multiple"
                    style={{ width: 'calc(30% - 16px)', marginRight: 8 }}
                    onChange={value => {selectedType === 'client' ? setCenter(checkAll(value, center)) : setBranch(checkAll(value, branch))}}
                    value={selectedType === 'client' ? center : branch}
                    placeholder={selectedType === 'client' ? 'Center' : selectedType === 'employee' ? 'Branch' : 'No Filters'}
                >
                    {/* Options based on userType */}
                    <Option value="*">All</Option>
                    {selectedType === 'client' && <>
                        <Option value="1">Center 1</Option>
                        <Option value="2">Center 2</Option>
                    </>}
                    {selectedType === 'employee' && <>
                        <Option value="1">Branch 1</Option>
                        <Option value="2">Branch 2</Option>
                    </>}
                </Select>
                <Select
                    mode="multiple"
                    style={{ width: 'calc(30% - 16px)', marginRight: 8 }}
                    onChange={value => {selectedType === 'client' ? setGroup(checkAll(value, group)) : setDesignation(checkAll(value, designation))}}
                    value={selectedType === 'client' ? group : designation}
                    placeholder={selectedType === 'client' ? 'Group' : selectedType === 'employee' ? 'Designation' : 'No Filters'}
                >
                    {/* Options based on userType */}
                    <Option value="*">All</Option>
                    {selectedType === 'client' && <>
                        <Option value="1">Group 1</Option>
                        <Option value="2">Group 2</Option>
                    </>}
                    {selectedType === 'employee' && <>
                        <Option value="1">Designation 1</Option>
                        <Option value="2">Designation 2</Option>
                    </>}
                </Select>
            </>
        );
    };

    return (
        <AppBody heading={"Course Mapping"}>
            <Content style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div style={{ display: 'flex', flex: 1 }}>
                    <div style={{ flex: 0.4, paddingRight: '20px' }}>
                        <Table
                            columns={columns}
                            dataSource={courses}
                            pagination={false}
                            scroll={{ y: 300 }}
                            rowSelection={{
                                selectedRowKeys,
                                onChange: setSelectedRowKeys,
                            }}
                        />
                    </div>
                    <div style={{ flex: 0.6 }}>
                        <Radio.Group onChange={handleTypeChange} value={selectedType}>
                            <Radio value="client">Client</Radio>
                            <Radio value="employee">Employee</Radio>
                            <Radio value="All">All</Radio>
                        </Radio.Group>
                        <Form layout="vertical" style={{ marginTop: '20px' }}>
                            {renderDynamicInputs()}
                        </Form>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 0' }}>
                            <Form.Item>
                                <Button type="default" onClick={resetForm} style={{marginRight: "15px"}}>Reset</Button>
                                <Button type="primary" onClick={submitForm}>Submit</Button>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Content>
        </AppBody>
    );
};

const mapStateToProps = state => ({
    courseList: state.courseList?.response,
    addCourseCriteria: state.addCourseCriteria?.response,
});

const mapDispatchToProps = dispatch => ({
    getCourses_: () => dispatch(getCourses()),
    addCourseCriteria_: (data) => dispatch(addCourseCriteria(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(SetCourseMap);
