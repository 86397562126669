import React, { useEffect, useState } from 'react';
import { Button, Input, Table, Space } from 'antd';
import { DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import AppBody from '../../components/Layout/AppBody';
import { connect } from 'react-redux';
import { getCourses } from '../../store/action/courses/courseAction';

const CourseList = (props) => {

    const [courses, setCourses] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const columns = [
        {
            title: 'ID',
            dataIndex: 'courseId',
            key: 'courseId',
            sorter: (a, b)=> a.courseId - b.courseId,
            defaultSortOrder: 'descend',
        },
        {
            title: 'Course Title',
            dataIndex: 'courseTitle',
            key: 'courseTitle',
            sorter: true, 
        },
        {
            title: 'Uploaded Date',
            dataIndex: 'uploadedDate',
            key: 'uploadedDate',
            sorter: true, 
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'uploadedDate',
            sorter: true, 
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'uploadedDate',
            sorter: true, 
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => a.status.localeCompare(b.status), 
            render: status => {
                let color = 'green';
                if (status === 'Inactive') {
                    color = '#CDB500';
                } else if (status === 'Deactivated') {
                    color = 'red';
                }
                return (
                    // <Tag color={color}>
                    <>
                        <span style={{ marginRight: 8, display: 'inline-block', width: 8, height: 8, borderRadius: '50%', backgroundColor: color }}></span>
                        {status}
                    </>
                    // </Tag>
                );
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => (
                <Space size="middle">
                    <Button icon={<EditOutlined />} onClick={()=>navigate(`/manage-course/${record.courseId}`)} />
                </Space>
            ),
        },
    ];

    const navigate = useNavigate();

    useEffect(() => {
        props.getCourses_();
    }, []);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredData =  courses
    ?.filter((course) => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        return (
            lowerCaseSearchTerm ? course.courseTitle?.toLowerCase().includes(lowerCaseSearchTerm) : true
        );
    });

    useEffect(() => {
        if(props.courseList && props.courseList.length > 0){
            setCourses(props.courseList.map(course => {
                let status = 'Deactivated';
                    if(course.isActive) {
                        const today = new Date();
                        const startDate = new Date(course.startDate);
                        const endDate = new Date(course.endDate);

                        if (today.toDateString() >= startDate.toDateString() && today.toDateString() <= endDate.toDateString()) {
                            status = 'Active';
                        }
                        else {
                            status = 'Inactive';
                        }
                    }

                return {
                    key: course.courseId,
                    courseId: course.courseId,
                    courseTitle: course.title,
                    uploadedDate: new Date(course.createdAt).toLocaleDateString(),
                    startDate: new Date(course.startDate).toLocaleDateString(),
                    endDate: new Date(course.endDate).toLocaleDateString(),
                    status: status,
                };
            }));
        }
    }, [props.courseList]);

    return (
        <AppBody heading={"Manage Course"} title="Course List" extra={<Button type="primary" onClick={()=>navigate("/add-course")}>Add New Course</Button>}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                <Button icon={<DownloadOutlined />}>Download CSV</Button>
                <Input placeholder="Search courses" style={{width: "200px"}} value={searchTerm} onChange={handleSearch}   />
            </div>
            <Table columns={columns} dataSource={filteredData} />
        </AppBody>
    );
};

const mapStateToProps = state => ({
    courseList: state.courseList?.response, // Adjust according to your state structure
});

const mapDispatchToProps = dispatch => ({
    getCourses_: () => dispatch(getCourses()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseList);