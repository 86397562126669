import { combineReducers } from "redux";
import {loginReducer, userReducer} from "./common/authReducer";
import { adminListReducer, createUserReducer, customerListReducer, staffListReducer, updateUserReducer, userDetailReducer } from "./users/userReducer";
import { activateLessonReducer, activateModuleReducer, addCourseCriteriaReducer, addCourseReducer, addLessonReducer, addModuleReducer, courseDetailReducer, courseListReducer, deactivateLessonReducer, deactivateModuleReducer, lessonDetailReducer, lessonUpdateReducer, modulesListReducer, updateCourseReducer, updateModuleReducer } from "./courses/courseReducer";
import { addDepartmentReducer, addLocationReducer, fetchDepartmentReducer, fetchDepartmentsReducer, fetchLocationReducer, fetchLocationsReducer, updateDepartmentReducer, updateLocationReducer, fetchBranchesReducer, fetchBranchReducer, updateBranchReducer, addBranchReducer, fetchDesignationsReducer, fetchDesignationReducer, updateDesignationReducer, addDesignationReducer, fetchGroupsReducer, fetchGroupReducer, updateGroupReducer, addGroupReducer, fetchCentresReducer, fetchCentreReducer, updateCentreReducer, addCentreReducer } from "./masters/mastersReducer";
// import posts from "./postReducer";

const rootReducer = combineReducers({
    login:  loginReducer,
    user: userReducer,
    customerList: customerListReducer,
    staffList: staffListReducer,
    adminList:adminListReducer,
    userDetail: userDetailReducer,
    createUser: createUserReducer,
    updateUser: updateUserReducer,
    courseList: courseListReducer,
    courseDetail: courseDetailReducer,
    modulesList: modulesListReducer,
    lessonDetail: lessonDetailReducer,
    lessonUpdate: lessonUpdateReducer,
    addLesson: addLessonReducer,
    updateModule: updateModuleReducer,
    addModule: addModuleReducer,
    addCourse: addCourseReducer,
    updateCourse: updateCourseReducer,
    deactivateModule: deactivateModuleReducer,
    activateModule: activateModuleReducer,
    deactivateLesson: deactivateLessonReducer,
    activateLesson: activateLessonReducer,
    addCourseCriteria: addCourseCriteriaReducer,
    locations: fetchLocationsReducer,
    location: fetchLocationReducer,
    addLocation: addLocationReducer,
    updateLocation: updateLocationReducer,
    departments: fetchDepartmentsReducer,
    department: fetchDepartmentReducer,
    updateDepartment: updateDepartmentReducer,
    addDepartment: addDepartmentReducer,
    branches: fetchBranchesReducer,
    branch: fetchBranchReducer,
    updateBranch: updateBranchReducer,
    addBranch: addBranchReducer,
    designations: fetchDesignationsReducer,
    designation: fetchDesignationReducer,
    updateDesignation: updateDesignationReducer,
    addDesignation: addDesignationReducer,
    groups: fetchGroupsReducer,
    group: fetchGroupReducer,
    updateGroup: updateGroupReducer,
    addGroup: addGroupReducer,
    centres: fetchCentresReducer,
    centre: fetchCentreReducer,
    updateCentre: updateCentreReducer,
    addCentre: addCentreReducer,
});

export default rootReducer;
